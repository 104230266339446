<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        class="mr-2 btn-fix action-btn"
      >
        Seminar Videos
        <v-icon right dark>mdi-video-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Seminar Videos
      </v-card-title>

      <v-card-text>
        <v-stepper v-model="e1" non-linear vertical>
          <v-row>
            <v-col cols="12" md="3" class="text-left">
              <v-btn
                v-for="(video, index) in videos"
                class="text-left text-wrap"
                v-bind:key="index"
                text
                style="width: 100%; justify-content: left;"
                @click="e1 = index"
              >
                {{ video.title }}
              </v-btn>
              <v-btn
                class="text-left text-wrap"
                text
                style="width: 100%; justify-content: left;"
                href="https://annuitycheck.s3.us-west-2.amazonaws.com/transcript.pdf"
                download
                target="_blank"
              >
                Full Transcript (download)
              </v-btn>
            </v-col>
            <v-col cols="12" md="9">
              <template v-for="(video, index) in videos">
                <div
                  class=""
                  v-bind:key="index"
                  v-if="dialog && e1 == index"
                  style="width: 100%; padding: 10px;"
                >
                  <div
                    style="position: relative; height: 100%; min-height: 400px; width: 100%; background-color: #000;"
                  >
                    <iframe
                      :src="video.embed"
                      style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                      allowfullscreen="true"
                    ></iframe>
                  </div>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-stepper>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.btn-fix:focus::before {
  opacity: 0 !important;
}

.v-btn__content {
  width: 100%;
  white-space: normal !important;
}

@media only screen and (max-width: 600px) {
  .action-btn {
    margin-top: 2px;
    margin-bottom: 2px;
    width: 100%;
  }
}
</style>

<script>
export default {
  data() {
    return {
      e1: 0,
      dialog: false,
      videos: [
        {
          title: "Remove Fees",
          embed:
            "https://iframe.videodelivery.net/0308c0a45e0ed3aa78ff632cbe03ae4b?poster=https%3A%2F%2Fvideodelivery.net%2F0308c0a45e0ed3aa78ff632cbe03ae4b%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D2s%26height%3D600",
        },
        {
          title: "Remove Volatility",
          embed:
            "https://iframe.videodelivery.net/1b2941fe2fea9668627f8524a7dfbf3d?poster=https%3A%2F%2Fvideodelivery.net%2F1b2941fe2fea9668627f8524a7dfbf3d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D2s%26height%3D600",
        },
        {
          title: "Remove Riders",
          embed:
            "https://iframe.videodelivery.net/4ae92db601a498cf65fd49ccef5d1121?poster=https%3A%2F%2Fvideodelivery.net%2F4ae92db601a498cf65fd49ccef5d1121%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D2s%26height%3D600",
        },
        {
          title: "Remove Surrender Charges",
          embed:
            "https://iframe.videodelivery.net/207f4a51b2c3521ac24d1e53ef41b113?poster=https%3A%2F%2Fvideodelivery.net%2F207f4a51b2c3521ac24d1e53ef41b113%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D2s%26height%3D600",
        },
        {
          title: "Remove Caps",
          embed:
            "https://iframe.videodelivery.net/33fad30a10fade0c705aa5deb17b882d?poster=https%3A%2F%2Fvideodelivery.net%2F33fad30a10fade0c705aa5deb17b882d%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D2s%26height%3D600",
        },
        {
          title: "Full Video",
          embed:
            "https://iframe.videodelivery.net/be0c7287de9476adfb32cf5eb0206db5?poster=https%3A%2F%2Fvideodelivery.net%2Fbe0c7287de9476adfb32cf5eb0206db5%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D3s%26height%3D600",
        },
      ],
    };
  },
};
</script>
